<!-- Código html -->
<template>
    
    <!-- Contenedor principal -->
    <div id="barra_superior">

        <b-container id="contenedor_barra_superior">

            <div>

                <img src="@/assets/imagenes/logo.svg" 
                    alt="Pogen"
                    id="logo_pogen"
                    title="Pogen">

            </div>

            <div>

                <img src="@/assets/imagenes/bandera_mexico.svg" 
                    alt="ES"
                    title="Español"
                    class="bandera_lenguaje"
                    @click="eIdioma('es')" />

                <img src="@/assets/imagenes/bandera_eua.svg" 
                    alt="EN"
                    title="English"
                    class="bandera_lenguaje"
                    @click="eIdioma('en')" />
                
            </div>

        </b-container>

    </div>

</template>

<!-- Código vue -->
<script>

    import {mapMutations,mapState} from 'vuex'

    export default{

        name:'barra_superior',

        computed:{
            ...mapState('sesion',['idioma'])
        },

        methods:{

            ...mapMutations('sesion',['establecerIdioma']),

            eIdioma(idioma){
                console.log(idioma);
                if(idioma !== this.idioma){
                    if(idioma === 'es'){
                        this.$router.push({name:'inicio_es'});
                    }else{
                        this.$router.push({name:'inicio_en'})
                    }
                }
                this.establecerIdioma(idioma);
            }

        }

    }

</script>


<!-- Estilo -->