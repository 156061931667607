  import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'inicio',
    redirect:'/es'
  },
  {
    path:'/es',
    name:'inicio_es',
    component:()=>import('../views/inicio_es.vue')
  },
  {
    path:'/en',
    name:'inicio_en',
    component:()=>import('../views/inicio_en.vue')
  },
  {
    path:'/ejemplos_api/es',
    name:'ejemplos_api_es',
    component:()=>import('../views/ejemplos_api_es')
  },
  {
    path:'/ejemplos_ws/es',
    name:'ejemplos_ws_es',
    component:()=>import('../views/ejemplos_ws_es')
  },
  {
    path:'/ejemplos_api/en',
    name:'ejemplos_api_en',
    component:()=>import('../views/ejemplos_api_en')
  },
  {
    path:'/ejemplos_ws/en',
    name:'ejemplos_ws_en',
    component:()=>import('../views/ejemplos_ws_en')
  },  
  {
    path:'*',
    name:'error_404',
    component:()=>import('../views/error_404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
