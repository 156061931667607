<!-- Código html -->
<template>

  <!-- Contenedor principal -->
  <div id="App">

    <barra_superior></barra_superior>
    <barra_vinculos></barra_vinculos>

    <!-- Router -->
    <b-container>
      <router-view/>
    </b-container>

  </div>
</template>

<!-- Código vue -->
<script>

  import barra_superior from './components/barra_superior.vue'
  import barra_vinculos from './components/barra_vinculos.vue'
  import {mapMutations} from 'vuex'

  export default{

    name:'App',

    components:{
      barra_superior,
      barra_vinculos
    },

    methods:{

      ...mapMutations('sesion',['establecerIdioma'])

    }

  }

</script>

<!-- Estilo propio -->
<style lang="scss">
</style>
