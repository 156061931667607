export default{

    namespaced: true,

    state:{

        idioma:'es'

    },

    mutations:{

        establecerIdioma(state,idioma){
            state.idioma = idioma;
        }

    }

}