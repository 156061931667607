<!-- Código html -->
<template>
    
    <!-- Contenedor principal -->
    <b-container>
        <div id="contiene_vinculos">
            <router-link v-if="idioma === 'es' && $route.name !== 'inicio_es'" class="cada_vinculo" :to="{name:'inicio_es'}">Manual</router-link>
            <router-link v-if="idioma === 'es' && $route.name !== 'ejemplos_api_es'" class="cada_vinculo" :to="{name:'ejemplos_api_es'}">Ejemplos API</router-link>
            <router-link v-if="idioma === 'es' && $route.name !== 'ejemplos_ws_es'" class="cada_vinculo" :to="{name:'ejemplos_ws_es'}">Ejemplos Web Service</router-link>
            <router-link v-if="idioma === 'en' && $route.name !== 'inicio_en'" class="cada_vinculo" :to="{name:'inicio_en'}">Manual</router-link>
            <router-link v-if="idioma === 'en' && $route.name !== 'ejemplos_api_en'" class="cada_vinculo" :to="{name:'ejemplos_api_en'}">API Examples</router-link>
            <router-link v-if="idioma === 'en' && $route.name !== 'ejemplos_ws_en'" class="cada_vinculo" :to="{name:'ejemplos_ws_en'}">Web Service Examples</router-link>
        </div>
    </b-container>

</template>

<!-- Código Vue -->
<script>

    import {mapState} from 'vuex'

    export default{

        name:'barra_vinculos',

        computed:{

            ...mapState('sesion',['idioma'])

        }

    }

</script>

<!-- Estilo propio -->